import React, { useState, useEffect } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators, numberAtLeast } from '../../util/validators';
import { Form, Button, FieldTextInput, FieldDateInput, FieldCheckboxGroup } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import arrayMutators from 'final-form-arrays';

import css from './EditListingDescriptionForm.module.css';
import CustomFieldCapacity from './CustomFieldCapacity';

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{
      ...arrayMutators,
    }}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        publicData,
        values,
        form,
        initialValues,
      } = formRenderProps;

      const [availableFromComparingValue, setAvailableFromComparingValue] = useState(
        initialValues.availableFrom
      );

      const isOfferring = publicData && publicData.category === 'offeringPlace';
      const isLooking = publicData && publicData.category === 'lookingForPlace';
      const privatquartier = publicData && publicData.type === 'privatquartier';
      const wohnmobilstellplatz = publicData && publicData.type === 'wohnmobilstellplatz';

      const titleMessage = isLooking ? intl.formatMessage({ id: 'EditListingDescriptionForm.titleOffer' }) : intl.formatMessage({ id: 'EditListingDescriptionForm.titleLooking' });
      const titlePlaceholderMessage = (
        isLooking && privatquartier
          ? intl.formatMessage({ id: 'EditListingDescriptionForm.titleLookingPrivateroomPlaceholder' })
          : isLooking && !privatquartier
            ? intl.formatMessage({ id: 'EditListingDescriptionForm.titleLookingCamperPlaceholder' })
            : isOfferring && wohnmobilstellplatz
              ? intl.formatMessage({ id: 'EditListingDescriptionForm.titleOfferCamperPlaceholder' })
              : isOfferring && !wohnmobilstellplatz
                ? intl.formatMessage({ id: 'EditListingDescriptionForm.titleOfferPrivateroomPlaceholder' })
                : null
      );
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = isLooking ? intl.formatMessage({ id: 'EditListingDescriptionForm.descriptionOffer' }) : intl.formatMessage({ id: 'EditListingDescriptionForm.descriptionLooking' });
      const descriptionPlaceholderMessage = (
        isLooking && privatquartier
          ? intl.formatMessage({ id: 'EditListingDescriptionForm.descriptionLookingPrivateroomPlaceholder' })
          : isLooking && !privatquartier
            ? intl.formatMessage({ id: 'EditListingDescriptionForm.descriptionLookingCamperPlaceholder' })
            : isOfferring && wohnmobilstellplatz
              ? intl.formatMessage({ id: 'EditListingDescriptionForm.descriptionOfferCamperPlaceholder' })
              : isOfferring && !wohnmobilstellplatz
                ? intl.formatMessage({ id: 'EditListingDescriptionForm.descriptionOfferPrivateroomPlaceholder' })
                : null
      );
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled =
        invalid ||
        disabled ||
        submitInProgress ||
        !values.title ||
        !values.availableFrom ||
        !values.availableUntil ||
        (privatquartier ? !values.capacityNumberOfPeople : false) ||
        (privatquartier ? !values.accessibility : false);


      const onHandleChange = formValues => {
        if (
          availableFromComparingValue.date.getDate() !==
          formValues.values.availableFrom.date.getDate() &&
          formValues.values.availableUntil.date.getDate() !==
          formValues.values.availableFrom.date.addDays(1).getDate()
        ) {
          form.change('availableUntil', {
            date: formValues.values.availableFrom.date.addDays(1),
          });
          setAvailableFromComparingValue(formValues.values.availableFrom);
        }
      };

      const isDayBlocked = day => {
        return day <= values.availableFrom.date;
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FormSpy onChange={onHandleChange} />

          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
          // validate={composeValidators(required(descriptionRequiredMessage))}
          />

          {wohnmobilstellplatz &&
            <FieldTextInput
              id="size"
              name="size"
              className={css.description}
              type="text"
              label={isLooking ? 'Wie groß soll der Stellplatz sein?' : 'Wie groß ist der Stellplatz?'}
              placeholder={'Zum Beispiel: 4 x 5 Meter'}
            // validate={composeValidators(required(descriptionRequiredMessage))}
            />
          }

          <div className={css.datesFieldsWrapper}>
            <FieldDateInput
              id="availableFrom"
              name="availableFrom"
              label={isLooking ? 'Von' : 'Verfügbar ab*'}
              placeholderText="Datum auswählen"
              validate={composeValidators(required('Sie müssen dieses Feld ausfüllen'))}
            />

            <FieldDateInput
              id="availableUntil"
              name="availableUntil"
              label={isLooking ? 'Bis' : 'Verfügbar bis*'}
              placeholderText="Datum auswählen"
              className={css.availableUntil}
              validate={composeValidators(required('Sie müssen dieses Feld ausfüllen'))}
              isDayBlockedFunction={isDayBlocked}
            />
          </div>

          <FieldTextInput
            id="language"
            name="language"
            className={css.title}
            type="text"
            label={'Welche Sprache(n) sprichst Du?'}
            placeholder={'Deutsch, Englisch, Gebärdensprache usw.'}
          />

          {privatquartier &&
            <CustomFieldCapacity
              id="capacityNumberOfPeople"
              name="capacityNumberOfPeople"
              label={isLooking ? 'Wie viele Personen sollen beherbergt werden?' : "Wie viele Personen können beherbergt werden?"}
            />
          }

          {/* <FieldTextInput
            id="capacityDescription"
            name="capacityDescription"
            className={css.title}
            type="text"
            label={isOfferring ? 'Wer kann beherbergt werden?' : 'Wer müsste beherbergt werden?'}
            placeholder={'2 Erwachsene und 2 Kinder oder 3 Erwachsene.'}
          /> */}


          {privatquartier &&
            <>
              <FieldCheckboxGroup
                className={css.checkboxGroup}
                id={'accessibility'}
                name={'accessibility'}
                label={
                  isOfferring
                    ? 'Ist die Unterkunft barrierefrei?*'
                    : 'Soll die Unterkunft barrierefrei sein?*'
                }
                options={[
                  {
                    key: 'yes',
                    label: 'Ja',
                  },
                  {
                    key: 'no',
                    label: 'Nein',
                  },
                ]}
                twoColumns={true}
                radio={true}
                validate={composeValidators(required("Du musst eine Option wählen."))}
              />
              <p className={css.explanatoryText}>
                {
                  'D.h. die Unterkunft muss für behinderte Menschen in der allgemein üblichen Weise ohne besondere Erschwernis und grundsätzlich ohne fremde Hilfe zugänglich und nutzbar sein.'
                }
              </p>

              {values.accessibility === 'yes' && (
                <FieldTextInput
                  id="accessibilityInfo"
                  name="accessibilityInfo"
                  className={css.title}
                  type="text"
                  label={'Details zur Barrierefreiheit'}
                  placeholder={
                    isOfferring
                      ? 'Bitte beschreibe das Level an Barrierefreiheit '
                      : 'Bitte beschreibe das benötigte Level an Barrierefreiheit'
                  }
                />
              )}
            </>
          }


          {isLooking && privatquartier && (
            <>
              <FieldCheckboxGroup
                className={css.features}
                id={'assistanceAnimals'}
                name={'assistanceAnimals'}
                label={'Hast Du Assistenztiere?'}
                options={[
                  {
                    key: 'yes',
                    label: 'Ja',
                  },
                  {
                    key: 'no',
                    label: 'Nein',
                  },
                ]}
                twoColumns={true}
                radio={true}
              />

              {values.assistanceAnimals === 'yes' && (
                <FieldTextInput
                  id="assistanceAnimalsInfo"
                  name="assistanceAnimalsInfo"
                  className={css.title}
                  type="text"
                  label={'Welche Assistenztiere hast Du?'}
                  placeholder={'Bitte gib an, welche Assistenztiere (z.B. Hund) Du hast'}
                />
              )}
            </>
          )}

          {isOfferring && privatquartier && (
            <>
              <FieldCheckboxGroup
                className={css.checkboxGroup}
                id={'pets'}
                name={'pets'}
                label={'Hast Du Haustiere?'}
                options={[
                  {
                    key: 'yes',
                    label: 'Ja',
                  },
                  {
                    key: 'no',
                    label: 'Nein',
                  },
                ]}
                twoColumns={true}
                radio={true}
              />

              {values.pets === 'yes' && (
                <FieldTextInput
                  id="petsInfo"
                  name="petsInfo"
                  className={css.title}
                  type="text"
                  label={'Welche Haustiere hast Du?'}
                  placeholder={'Bitte gib an, welche Haustiere Du hast (z.B. Hund)'}
                />
              )}

            </>
          )}
          {isLooking && privatquartier && (
            <>
              <FieldCheckboxGroup
                className={css.checkboxGroup}
                id={'allergies'}
                name={'allergies'}
                label={'Hast Du Allergien?'}
                options={[
                  {
                    key: 'yes',
                    label: 'Ja',
                  },
                  {
                    key: 'no',
                    label: 'Nein',
                  },
                ]}
                twoColumns={true}
                radio={true}
              />

              {values.allergies === 'yes' && (
                <FieldTextInput
                  id="allergiesInfo"
                  name="allergiesInfo"
                  className={css.title}
                  type="text"
                  label={'Welche Allergien hast Du?'}
                  placeholder={'Bitte spezifiziere Deine Allergien (z.B. Katzenhaarallergie)'}
                />
              )}
            </>
          )}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            // disabled={!values.title}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>

          <p>
            <i>* Pflichtfeld</i>
          </p>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
