import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <i>Stand: Juni 2024</i>
      <p>
        Willkommen bei unterkunft-kirchentag.de (nachfolgend: „Unterkunft-Kirchentag“)! Die
        nachfolgenden Nutzungsbedingungen regeln die Nutzung von „Unterkunft-Kirchentag“ durch
        registrierte Nutzer (nachfolgend: „Mitgliedschaft“) über das Internet, mobile Dienste, Apps,
        andere Domains oder Kommunikationswege.
      </p>

      <p>
        Dein Ansprech- und Vertragspartner für „Unterkunft-Kirchentag“ ist die Churchpool GmbH,
        nachfolgend auch „wir“ genannt. Nähere Informationen findest du im Impressum.
      </p>

      <p>
        <strong>1 Anmeldung und Registrierung, Benutzerkonto</strong>
      </p>

      <p>
        1.1 Die Mitgliedschaft wird durch das erfolgreiche Ausfüllen und Abschließen des
        Registrierungsprozesses begründet. Ein Anspruch auf Mitgliedschaft besteht nicht.
      </p>
      <p>
        1.2 Für die Anmeldung musst Du mindestens 16 Jahre alt sein. Weitere Informationen hierzu
        findest Du in unserer Datenschutzerklärung.
      </p>
      <p>
        1.3 Bei der Anmeldung musst du wahrheitsgemäße, aktuelle und vollständige Angaben machen.
        Deine Daten, vor allem Name und E-Mail-Adresse, musst Du in Deinem Mitgliedskonto stets
        aktuell halten. Mehrfache Anmeldungen unter verschiedenen Nutzernamen sind untersagt; eine
        erneute Anmeldung, wenn wir eine Mitgliedschaft gekündigt und die Neuanmeldung verboten
        haben, ist ebenfalls untersagt.
      </p>
      <p>
        1.4 Die Mitgliedschaft ist persönlich und Deine Zugangsdaten darfst nur du selbst verwenden,
        nicht z.B. auch Familienmitglieder oder Freunde. Du musst die Zugangsdaten zu Deinem
        Benutzerkonto geheim halten. Bitte informiere uns umgehend, wenn du einen Missbrauch
        feststellst.
      </p>

      <p>
        <strong>2 Laufzeit und Kündigung</strong>
      </p>

      <p>
        2.1 Die Mitgliedschaft läuft auf unbestimmte Zeit. Sie kann von beiden Seiten jederzeit mit
        sofortiger Wirkung ordentlich gekündigt werden.{' '}
      </p>
      <p>
        2.2 Das gesetzliche Recht zur Kündigung aus wichtigem Grund bleibt unberührt. Im Fall der
        fristlosen Kündigung sind wir berechtigt, Dich inaktiv zu schalten oder Dein Nutzerkonto zu
        löschen.
      </p>

      <p>
        <strong>3 Nutzung von "Unterkunft-Kirchentag“</strong>
      </p>
      <p>
        3.1 Bei der Nutzung von „Unterkunft-Kirchentag“ verpflichtest Du Dich, die geltenden
        gesetzlichen Vorschriften zu beachten. Das betrifft z.B. den Schutz der
        Persönlichkeitsrechte anderer Nutzer oder das Urheberrecht bzw. bestehende Nutzungsrechte an
        Fotos oder anderen Werken im Sinne des UrhG.
      </p>

      <p>
        3.2 „Unterkunft-Kirchentag“ ist kostenlos. Wir übernehmen keine Garantie oder Gewähr für
        Informationen auf „Unterkunft-Kirchentag“, für Inhalte anderer Nutzer oder für eine
        bestimmte Verfügbarkeit oder Funktionalität von „Unterkunft-Kirchentag“.
      </p>
      <p>
        3.3 Wir kontrollieren auf „Unterkunft-Kirchentag” gespeicherte Inhalte von Nutzern nicht
        vorab. Wir behalten uns aber vor, sowohl Nutzer als auch ihre Inhalte jederzeit zu sperren
        oder zu löschen. Dies würden wir insbesondere tun, wenn wir Kenntnis von einem Verstoß gegen
        geltendes Recht oder gegen unsere Nutzungsbedingungen erhalten. Eine Löschung und / oder
        Sperrung aus anderen Gründen behalten wir uns ausdrücklich vor. Diese Gründe liegen in
        unserem Ermessen. Wir sind nicht dazu verpflichtet, die Nutzer über diese Gründe zu
        informieren.
      </p>
      <p>
        3.4 Du darfst Dein privates Profil bei „Unterkunft-Kirchentag” nicht für geschäftliche
        Zwecke, z.B. für das gewerbliche Angebot von Waren oder Dienstleistungen oder Werbung,
        nutzen.
      </p>

      <p>
        3.5 Du bleibst Eigentümer Deiner Inhalte (z.B. von Dir auf „Unterkunft-Kirchentag”
        veröffentlichte Profilfotos), räumst Unterkunft-Kirchentag jedoch die für die
        gegenständliche Nutzung erforderlichen Rechte vollumfänglich ein, d.h. Du gewährst uns eine
        nicht-exklusive, übertragbare, unterlizenzierbare und zeitlich und räumlich unbeschränkte
        Lizenz, Deine Inhalte zu hosten, zu verwenden, zu verbreiten, zu modifizieren, auszuführen,
        zu kopieren, öffentlich vorzuführen oder anzuzeigen, zu übersetzen und abgeleitete Werke
        davon zu erstellen. Diese Lizenz dient nur für die Bereitstellung der entsprechenden Inhalte
        auf Unterkunft-Kirchentag.{' '}
      </p>
      <p>
        3.6 An der Struktur und den Funktionalitäten von „Unterkunft-Kirchentag” sowie deren
        Bezeichnungen und den Datenbanken von „Unterkunft-Kirchentag” behalten bzw. erwerben bei
        Fortentwicklung nur wir die exklusiven und uneingeschränkten Rechte.
      </p>

      <p>
        <strong>4 Änderung der Nutzungsbedingungen</strong>
      </p>
      <p>
        Unterkunft-Kirchentag ist berechtigt, diese Nutzungsbedingungen mit Wirkung für die Zukunft
        nach einer entsprechenden Mitteilung zu ändern. Die Änderung gilt als genehmigt, wenn Du
        Deinen Account nicht innerhalb von 14 Tagen nach Bekanntgabe der Änderung gelöscht hast.
      </p>

      <p>
        <strong> 5. Datenschutz </strong>
      </p>
      <p>
        „Unterkunft-Kirchentag“ unterliegt deutschem und europäischem Datenschutzrecht.
        Informationen zur Erhebung, Verarbeitung und Nutzung personenbezogener Daten findest Du in
        unserer Datenschutzerklärung auf unserer Webseite unter{' '}
        <a href="https://unterkunft-kirchentag.de/privacy-policy"></a>
        https://unterkunft-kirchentag.de/privacy-policy.
      </p>

      <p>
        <strong>
          6. Haftungsbeschränkung, Ausschluss des Rücktritts bei bestimmten Pflichtverletzungen
        </strong>
      </p>

      <p>
        6.1 Unterkunft-Kirchentag haftet unbeschränkt nach dem Produkthaftungsgesetz, für
        vorsätzlich oder grob fahrlässig verursachte Schäden, bei arglistigem Verschweigen von
        Mängeln, sowie für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit.
        Auch die Haftung für Schäden aus der Verletzung einer Garantie ist unbeschränkt.
      </p>

      <p>
        6.2 Bei Verletzung wesentlicher Vertragspflichten (sog. Kardinalpflichten), die nur auf
        einfacher Fahrlässigkeit beruht, haftet Unterkunft-Kirchentag beschränkt auf den Ersatz des
        vorhersehbaren, vertragstypischen Schadens.
      </p>

      <p>
        6.3 Außer in den in den Absätzen 1 und 2 genannten Fällen haftet Unterkunft-Kirchentag nicht
        für Schäden, die durch einfache Fahrlässigkeit verursacht wurden.
      </p>
      <p>
        6.4 Soweit die Haftung von Unterkunft-Kirchentag nach den vorstehenden Absätzen
        ausgeschlossen oder beschränkt ist, gilt dies auch für die Haftung ihrer Erfüllungs- und
        Verrichtungsgehilfen.
      </p>

      <p>
        <strong>7. Schlussbestimmungen</strong>
      </p>
      <p>
        7.1 Sollten einzelne Bestimmungen dieser Nutzungsbedingungen ganz oder teilweise ungültig
        sein, bleibt die Gültigkeit der übrigen Bestimmungen davon unberührt. Die ungültige
        Bestimmung ist dann durch eine durchführbare Bestimmung zu ersetzen,die dem Zweck der
        ungültigen Bestimmung wirtschaftlich am nächsten kommt. Gleiches gilt für den Fall, dass die
        Nutzungsbedingungen Regelungslücken enthalten.
      </p>
      <p>7.2 Es gilt deutsches Recht.</p>

      <p>7.3 Gerichtsstand ist Bremen, Deutschland.</p>
      <p>
        7.4 Die Europäische Kommission stellt ab dem 15.02.2016 hier eine Plattform zur
        Online-Streitbeilegung bereit. Die E-Mail-Adresse von Unterkunft-Kirchentag lautet
        kontakt@unterkunft-kirchentag.de. Unterkunft-Kirchentag ist nicht bereit oder verpflichtet,
        an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
