import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <i>Stand: Juni 2024</i>
      <p>
        Datenschutz ist uns ein wichtiges Anliegen. Deshalb erfährst Du in dieser
        Datenschutzerklärung, welche Daten wie verwendet werden, wenn Du Dich entschließt, unsere
        unterkunft-kirchentag.de-Webseite (im Folgenden "Webseite" oder "unterkunft-kirchentag.de"
        genannt) zu verwenden. Insbesondere möchten wir Dir erläutern, wie wir den Schutz Deiner
        personenbezogenen Daten sicherstellen.{' '}
      </p>
      <p>
        <strong>Verantwortlicher</strong>
      </p>
      <p>
        Dein Ansprechpartner für die Webseite und auch Betreiber der Plattform
        unterkunft-kirchentag.de ("wir", "uns") ist die
      </p>
      <p>
        Churchpool GmbH
        <br />
        Überseetor 20
        <br />
        28217 Bremen
      </p>
      <p>
        Wir sind auch der sogenannte Verantwortliche im Sinne der Datenschutz-Grundverordnung
        (DSGVO) für die Verarbeitung Deiner personenbezogenen Daten, wenn Du
        unterkunft-kirchentag.de nutzt. Wenn du Fragen oder Anliegen hierzu hast, steht dir unser
        Support-Team per E-Mail unter kontakt@unterkunft-kirchentag.de gern zur Verfügung.{' '}
      </p>
      <p>
        <strong>Kontaktdaten des Datenschutzbeauftragten</strong>
      </p>
      <p>
        Unser externer Datenschutzbeauftragter steht dir ebenfalls gerne für Auskünfte zum Thema
        Datenschutz unter folgenden Kontaktdaten zur Verfügung:
      </p>
      <p>
        datenschutz nord GmbH
        <br />
        Konsul-Smidt-Strasse 88
        <br />
        28217 Bremen
        <br />
        Web: www.datenschutz-nord-gruppe.de
        <br />
        E-Mail: office@datenschutz-nord.de
      </p>
      <p>
        Wenn Du Dich an unseren Datenschutzbeauftragten wendest, verweise hierbei bitte auf
        unterkunft-kirchentag.de.
      </p>
      <p>
        <strong>Nutzungsdaten</strong>
      </p>
      <p>
        Wenn du unsere Webseite nutzt, werden auf unseren Servern temporär sogenannte Nutzungsdaten
        zu technischen und statistischen Zwecken sowie zur Erkennung und Behebung von Fehlern
        übertragen. Dies ist erforderlich, damit die Webseite ordnungsgemäß funktionieren und um die
        Qualität von unterkunft-kirchentag.de zu verbessern. Dieser Datensatz besteht u. a. aus:
      </p>
      <ul>
        <li>• dem Namen und der Adresse der angeforderten Inhalte,</li>
        <li>• dem Datum und der Uhrzeit der Abfrage,</li>
        <li>• der übertragenen Datenmenge,</li>
        <li>• dem Zugriffsstatus (Inhalt übertragen, Inhalt nicht gefunden),</li>
        <li>
          • den Angaben über den verwendeten Webbrowser und das Betriebssystems bzw. Typ und Version
          Deines mobilen Geräts (z. B. „iPhone 6, iOS 8.1“){' '}
        </li>
        <li>• ggf. dem Referral-Link, der angibt, von welcher Seite Du auf unsere gelangt bist,</li>
        <li>• der IP-Adresse des anfragenden Geräts.</li>
      </ul>
      <p>
        <strong>Datensicherheit</strong>
      </p>
      <p>
        Um Deine Daten vor unerwünschten Zugriffen möglichst umfassend zu schützen, treffen wir
        technische und organisatorische Maßnahmen. Wir setzen auf unterkunft-kirchentag.de ein
        Verschlüsselungsverfahren ein. Deine Angaben werden von Deinem Rechner zu unserem Server und
        umgekehrt über das Internet mittels einer Transport Layer Security (TLS)-Verschlüsselung
        übertragen.
      </p>
      <p>
        <strong>Erforderliche Cookies</strong>
      </p>
      <p>
        Wir setzen Cookies ein, die zur Nutzung unserer Webseite erforderlich sind. Cookies sind
        kleine Textdateien, die auf deinem Endgerät gespeichert und ausgelesen werden können. Man
        unterscheidet zwischen Session-Cookies, die wieder gelöscht werden, sobald du deinen Browser
        schließt und permanenten Cookies, die über die einzelne Sitzung hinaus gespeichert werden.{' '}
      </p>
      <p>
        Teilweise enthalten diese Cookies lediglich Informationen zu bestimmten Einstellungen und
        sind nicht personenbeziehbar. Sie können auch notwendig sein, um die Benutzerführung,
        Sicherheit und Umsetzung der Seite zu ermöglichen.{' '}
      </p>
      <p>Wir nutzen diese Cookies auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO. </p>
      <p>Weitere Informationen finden Sie in unserer Cookie-Richtlinie. </p>
      <p>
        Im Falle der Webseite, kannst du deinen Browser so einstellen, dass er dich über die
        Platzierung von Cookies informiert. So wird der Gebrauch von Cookies transparent. Du kannst
        Cookies zudem jederzeit über die entsprechende Browsereinstellung löschen und das Setzen
        neuer Cookies verhindern. Bitte beachte, dass die Webseite dann ggf. nicht korrekt angezeigt
        werden kann und einige Funktionen technisch nicht mehr zur Verfügung stehen.
      </p>
      <p>
        <strong>Kartendienste</strong>
      </p>
      <p>
        In unserer Webseite betten wir Kartendienste ein, die nicht auf unseren Servern gespeichert
        sind. Ein Aufruf unserer Seiten mit eingebetteten Kartendiensten führt dazu, dass Inhalte
        des Drittanbieters nachgeladen werden, der die Kartendienste bereitstellt. Hierdurch erhält
        der Drittanbieter die Information, dass Du unsere Seite aufgerufen hast sowie die in diesem
        Rahmen technisch erforderlichen Nutzungsdaten. Wir haben auf die weitere Datenverarbeitung
        durch den Drittanbieter keinen Einfluss. Die Einbettung erfolgt auf Grundlage von Art. 6
        Abs. 1 S. 1 lit. f DSGVO und in dem Interesse, Dir die Nutzung von Kartendiensten zu
        ermöglichen.
      </p>
      <p>
        Mapbox Inc.
        <br />
        Angemessenes Datenschutzniveau: EU-Standardvertrag
        <br />
        Möglichkeit zum Widerspruch: Wenn Du der Einbettung widersprechen möchtest, nutze unsere
        Webseite bitte nicht mehr.
      </p>
      <p>
        <strong>Weitere Auftragsverarbeiter</strong>
      </p>
      <p>
        Wir geben deine Daten im Rahmen einer Auftragsverarbeitung gem. Art. 28 DSGVO an
        Dienstleister weiter, die uns beim Betrieb von churchpool.com und der damit
        zusammenhängenden Prozesse unterstützen. Das sind z.B. Hosting-Dienstleister. Unsere
        Dienstleister sind uns gegenüber streng weisungsgebunden und entsprechend vertraglich
        verpflichtet.{' '}
      </p>
      <p>
        <strong>Registrierung</strong>
      </p>
      <p>
        Wenn Du Dich entschließt, Dich bei unterkunft-kirchentag.de zu registrieren, wirst Du dazu
        aufgefordert, uns folgende personenbezogene Daten mitzuteilen: Name und E-Mail. Ohne diese
        Daten ist eine Registrierung nicht und somit die Nutzung von unterkunft-kirchentag.de nur
        eingeschränkt möglich. In Verbindung mit den Registrierungsdaten des Anmelders bzw. Nutzers
        archivieren wir auch das Datum und die Uhrzeit der Registrierung. Rechtsgrundlage der
        Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. b DSGVO.
      </p>
      <p>
        <strong>Profildaten</strong>
      </p>
      <p>
        Weiterhin speichern wir sämtliche personenbezogenen Daten, die Du im Rahmen Deines Profils
        auf freiwilliger Basis anlegst (z. B. Profilfoto und Name). Rechtsgrundlage der Verarbeitung
        der Profildaten ist Art. 6 Abs. 1 s.1 lit. a DSGVO.
      </p>
      <p>
        <strong>Inhaltsdaten</strong>
      </p>
      <p>
        Die im Rahmen der Nutzung von unterkunft-kirchentag.de von Dir hinterlegten und
        bereitgestellten Inhalte (z. B. Bilder, Adress- und Kontaktdaten, Beschreibung etc.) werden
        ebenfalls von uns gespeichert. Rechtsgrundlage der Verarbeitung der Inhaltsdaten ist Art. 6
        Abs. 1 s.1 lit. a DSGVO.{' '}
      </p>
      <p>
        <strong>E-Mail-Benachrichtigungen </strong>
      </p>
      <p>
        Wir weisen Dich per E-Mail auf bestimmte Neuigkeiten auf unterkunft-kirchentag.de hin, auch
        wenn du die Webseite gerade nicht aktiv nutzt. Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. a
        DSGVO.
      </p>
      <p>
        <strong>Speicherdauer der Daten</strong>
      </p>
      <p>
        Grundsätzlich speichern wir personenbezogene Daten nur so lange, wie dies zur Erfüllung
        vertraglicher oder gesetzlicher Pflichten oder zur Geltendmachung zivilrechtlicher Ansprüche
        erforderlich ist.
      </p>
      <p>
        Registrierungsdaten und Profildaten werden bis zu vier Wochen nach der Löschung Deines
        Nutzerkontos gespeichert und anschließend gelöscht. Inhaltsdaten werden bis zur Löschung
        Deines Nutzerkontos gespeichert und anschließend anonymisiert.
      </p>
      <p>
        <strong>Deine Rechte als betroffene Person</strong>
      </p>
      <p>
        Bei der Verarbeitung deiner personenbezogenen Daten gewährt die DSGVO dir als betroffene
        Person bestimmte Rechte:
      </p>
      <p>
        Auskunftsrecht (Art. 15 DSGVO)
        <br />
        Du hast das Recht eine Bestätigung darüber zu verlangen, ob dich betreffende
        personenbezogene Daten verarbeitet werden; ist dies der Fall, so hast du ein Recht auf
        Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im Einzelnen
        aufgeführten Informationen.
      </p>
      <p>
        Recht auf Berichtigung (Art. 16 DSGVO)
        <br />
        Du hast das Recht, unverzüglich die Berichtigung dich betreffender unrichtiger
        personenbezogener Daten und ggf. die Vervollständigung unvollständiger Daten zu verlangen.
      </p>
      <p>
        Recht auf Löschung (Art. 17 DSGVO)
        <br />
        Du hast das Recht, zu verlangen, dass dich betreffende personenbezogene Daten unverzüglich
        gelöscht werden, sofern einer der in Art. 17 DSGVO im Einzelnen aufgeführten Gründe
        zutrifft.
      </p>
      <p>
        Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)
        <br />
        Du hast das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18
        DSGVO aufgeführten Voraussetzungen gegeben ist, z. B. wenn du Widerspruch gegen die
        Verarbeitung eingelegt hast, für die Dauer der Prüfung durch den Verantwortlichen.
      </p>
      <p>
        Recht auf Datenübertragbarkeit (Art. 20 DSGVO)
        <br />
        In bestimmten Fällen, die in Art. 20 DSGVO im Einzelnen aufgeführt sind, hast du das Recht,
        die dich betreffenden personenbezogenen Daten in einem strukturierten, gängigen und
        maschinenlesbaren Format zu erhalten bzw. die Übermittlung dieser Daten an einen Dritten zu
        verlangen.
      </p>
      <p>
        Widerrufsrecht (Art. 7 DSGVO)
        <br />
        Sofern die Verarbeitung von Daten auf Grundlage Ihrer Einwilligung erfolgt, bist du nach
        Art. 7 Abs. 3 DSGVO berechtigt, die Einwilligung in die Verwendung deiner personenbezogenen
        Daten jederzeit zu widerrufen. Bitte beachte, dass der Widerruf erst für die Zukunft wirkt.
        Verarbeitungen, die vor dem Widerruf erfolgt sind, sind davon nicht betroffen.
      </p>
      <p>
        Widerspruchsrecht (Art. 21 DSGVO)
        <br />
        Werden Daten auf Grundlage von Art. 6 Abs. 1 S. 1 lit. f DSGVO (Datenverarbeitung zur
        Wahrung berechtigter Interessen) oder auf Grundlage von Art. 6 Abs. 1 S. 1 lit. e DSGVO
        (Datenverarbeitung zur Wahrung öffentlichen Interesse oder in Ausübung öffentlicher Gewalt)
        erhoben, steht dir das Recht zu, aus Gründen, die sich aus deiner besonderen Situation
        ergeben, jederzeit gegen die Verarbeitung Widerspruch einzulegen. Wir verarbeiten die
        personenbezogenen Daten dann nicht mehr, es sei denn, es liegen nachweisbar zwingende
        schutzwürdige Gründe für die Verarbeitung vor, die gegenüber deinen Interessen, Rechten und
        Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen.
      </p>
      <p>
        Beschwerderecht bei einer Aufsichtsbehörde (Art. 77 DSGVO)
        <br />
        Du hast gem. Art. 77 DSGVO das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn du der
        Ansicht bist, dass die Verarbeitung der dich betreffenden Daten gegen datenschutzrechtliche
        Bestimmungen verstößt. Das Beschwerderecht kann insbesondere bei einer Aufsichtsbehörde in
        dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, deines Arbeitsplatzes oder des Orts
        des mutmaßlichen Verstoßes geltend gemacht werden.
      </p>
      <p>
        Geltendmachung Deiner Rechte
        <br />
        Zur Geltendmachung Deiner Betroffenenrechte wende Dich bitte an die Churchpool GmbH,
        Überseetor 20, 28217 Bremen.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
