import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaTwitter.module.css';

const IconSocialMediaTwitter = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    // <svg
    //   className={classes}
    //   width="16"
    //   height="14"
    //   viewBox="0 0 16 14"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M14.956 2.37c-.513.225-1.07.388-1.647.45.592-.36 1.044-.928 1.257-1.604-.55.334-1.17.577-1.816.703-.52-.568-1.265-.92-2.09-.92C9.077 1 7.8 2.307 7.8 3.912c0 .225.028.45.072.667C5.5 4.45 3.382 3.3 1.974 1.53c-.248.433-.39.928-.39 1.47 0 1.01.505 1.9 1.276 2.424-.47-.018-.912-.153-1.293-.37v.036c0 1.416.983 2.587 2.293 2.858-.24.063-.496.1-.752.1-.186 0-.363-.02-.54-.046.362 1.154 1.416 1.992 2.674 2.02-.983.783-2.214 1.243-3.55 1.243-.24 0-.462-.01-.692-.036 1.266.83 2.772 1.308 4.392 1.308 5.26 0 8.138-4.435 8.138-8.284 0-.126 0-.252-.008-.38.557-.413 1.045-.927 1.434-1.504z"
    //     fillRule="evenodd"
    //   />
    // </svg>

    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="19"
      height="19" viewBox="0 0 50 50">
      <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
    </svg>



  );
};

IconSocialMediaTwitter.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaTwitter.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaTwitter;
