import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import MobileLogoImage from './logoMobile.png';
// import DesktopLogoImage from './logoD.jpeg';
import DesktopLogoImage from './Logo_UK25.png';
import css from './Logo.module.css';

const Logo = props => {
  const { className, format, ...rest } = props;
  const isMobile = format !== 'desktop';
  const classes = classNames(className, { [css.logoMobile]: isMobile });
  // const logoImage = isMobile ? MobileLogoImage : DesktopLogoImage;
  const logoImage = DesktopLogoImage;

  return <img className={classes} src={logoImage} alt={config.siteTitle} {...rest} />;
};

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
