import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.module.css';
import { pushToPath } from '../../util/urlHelpers';

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitleDesktop}>
          <FormattedMessage
            id="SectionHero.titleDesktop"
            values={{ lineBreak: <br /> }} />
        </h1>
        <h1 className={css.heroMainTitleMobile}>
          <FormattedMessage
            id="SectionHero.titleMobile"
            values={{ lineBreak: <br /> }} />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        <a
          className={css.heroButton}
          onClick={() => {
            pushToPath(
              '/s?address=Hannover%2C%20Niedersachsen%2C%20Deutschland&bounds=53.26433294%2C11.017876%2C51.47692237%2C8.505124&pub_type=wohnmobilstellplatz&mapSearch=true'
            );
          }}
        >
          <FormattedMessage id="SectionHero.browseButton" />
        </a>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
