/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  // {
  //   id: 'dates',
  //   label: 'Dates',
  //   type: 'BookingDateRangeFilter',
  //   group: 'primary',
  //   // Note: BookingDateRangeFilter is fixed filter,
  //   // you can't change "queryParamNames: ['dates'],"
  //   queryParamNames: ['dates'],
  //   config: {},
  // },
  // {
  //   id: 'isVerified',
  //   label: 'Verified',
  //   type: 'SelectSingleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_isVerified'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [{ key: 'verified', label: 'Verified user' }],
  //   },
  // },
  {
    id: 'keyword',
    label: 'Stichwort',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'profileType',
    label: 'Art des Profils',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_profileType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'organisation', label: 'Organisation' },
        { key: 'private', label: 'Privatperson' },
      ],
    },
  },
  {
    id: 'organisationType',
    label: 'Organisation type',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_organisationType'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'NGO', label: 'NGO' },
        { key: 'church', label: 'Church' },
        { key: 'corporation', label: 'Corporation' },
        { key: 'other', label: 'Other' },
      ],
    },
  },
  {
    id: 'category',
    label: 'Art des Eintrags',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'lookingForPlace', label: 'Gesuch' },
        { key: 'offeringPlace', label: 'Angebot' },
      ],
    },
  },
  {
    id: 'listingType',
    label: 'Typ des Eintrags',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_type'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'privatquartier', label: 'Privatquartier' },
        { key: 'wohnmobilstellplatz', label: 'Wohnmobilstellplatz' },
      ],
    },
  },
  // {
  //   id: 'subcategories',
  //   label: 'Art der Unterkunft',
  //   type: 'SelectSingleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_subcategories'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'sharedAccomodation', label: 'Gemeinschaftsquartier' },
  //       { key: 'privateAccomodation', label: 'Privatquartier' },
  //       { key: 'camping', label: 'Camping' },
  //     ],
  //   },
  // },

  {
    id: 'capacityNumberOfPeople',
    label: 'Gästeanzahl',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['pub_capacityNumberOfPeople'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 50,
      step: 1,
    },
  },

  {
    id: 'accessibility',
    label: 'Barrierefreiheit',
    type: 'SelectSingleFilter', //'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_accessibility'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'yes',
          label: 'Ja',
        },
        {
          key: 'no',
          label: 'Nein',
        },
      ],
    },
  },
  {
    id: 'assistanceAnimals',
    label: 'Assistenztiere',
    type: 'SelectSingleFilter', //'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_assistanceAnimals'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'yes',
          label: 'Ja',
        },
        {
          key: 'no',
          label: 'Nein',
        },
      ],
    },
  },
  {
    id: 'pets',
    label: 'Haustiere',
    type: 'SelectSingleFilter', //'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_pets'],
    config: {
      searchMode: 'has_any',
      options: [
        {
          key: 'yes',
          label: 'Ja',
        },
        {
          key: 'no',
          label: 'Nein',
        },
      ],
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Neuste' },
    { key: '-createdAt', label: 'Älteste' },
    // { key: '-price', label: 'Lowest price' },
    // { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevanz', longLabel: 'Relevanz (Schlagwort suche)' },
  ],
};
